import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import InventoryList from "../inventory/InventoryList";
import {
  Autocomplete,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import _ from "lodash";

export default function FamilyList() {
  const navigate = useNavigate();

  const { accountName, familyId } = useParams();
  const [allPokemon, setAllPokemon] = useState();
  const [allSpecies, setAllSpecies] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [me, setMe] = useState([]);

  const [region, setRegion] = useState();
  const [shinyOnly, setShinyOnly] = useState();
  const [legendaryOnly, setLegendaryOnly] = useState();
  const [rocketOptions, setRocketOptions] = useState([]);

  useEffect(() => {
    async function getMe() {
      const response = await axios("/me");
      setMe(response.data);
    }
    getMe();
  }, []);

  useEffect(() => {
    async function getAllSpecies() {
      const response = await axios("/species/");
      setAllSpecies(response.data);
    }
    getAllSpecies();
  }, []);

  useEffect(() => {
    async function getAllRegions() {
      const response = await axios("/regions/");
      setAllRegions(response.data);
    }
    getAllRegions();
  }, []);

  useEffect(() => {
    async function getAllPokemon() {
      const response = await axios(
        `/accounts/${accountName}/families/${familyId}`
      );
      setRegion(undefined); // TODO: isolate state reset.
      setRocketOptions([]);
      setAllPokemon(response.data);
    }
    getAllPokemon();
  }, [accountName, familyId]);

  if (!allPokemon || !allSpecies) {
    return <></>;
  }

  const mine =
    me && me.accounts && me.accounts.map((a) => a.name).includes(accountName);
  console.log({ mine, accountName, me });
  console.log({
    all: allPokemon.inventory.map((i) => i.regionalSpecies.region),
  });

  return (
    <div>
      <div className="family-list-controls">
        <FormGroup style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div className="autocomplete">
            <Autocomplete
              disablePortal
              id="species-name"
              getOptionLabel={(option) => option.name}
              onChange={(a, b) =>
                b &&
                b.family_id &&
                navigate(`/accounts/${accountName}/families/${b.family_id}`)
              }
              //   getOptionSelected={(option, value) => option === value}
              options={allSpecies}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Species" />
              )}
            />
          </div>
          <div className="autocomplete">
            <Autocomplete
              disablePortal
              disabled={
                _.uniq(
                  allPokemon.inventory.map((i) => i.regionalSpecies.region)
                ).length <= 1
              } // only allow this to be selected when regions exist.
              id="region-name"
              getOptionLabel={(option) => option.name}
              onChange={(a, b) => b && setRegion(() => b.id)}
              options={allRegions.filter((r) =>
                allPokemon.inventory
                  .map((i) => i.regionalSpecies.region)
                  .includes(r.id)
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Region" />}
            />
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={shinyOnly}
                onChange={(e) => setShinyOnly(e.target.checked)}
              />
            }
            label="Shiny"
          />
          <FormControlLabel
            control={
              <Switch
                checked={legendaryOnly}
                onChange={(e) => setLegendaryOnly(e.target.checked)}
              />
            }
            label="Legendary"
          />
          <ToggleButtonGroup
            size="small"
            aria-label="Small sizes"
            value={rocketOptions}
            onChange={(_, a) => setRocketOptions(a)}
          >
            <ToggleButton value="Shadow">Shadow</ToggleButton>
            {/* <ToggleButton value="Purified">Purified</ToggleButton> */}
          </ToggleButtonGroup>
        </FormGroup>
      </div>
      <InventoryList
        inventory={allPokemon.inventory
          .filter((i) => (region ? i.regionalSpecies.region === region : true))
          .filter((i) => (rocketOptions.includes("Shadow") ? i.shadow : true))
          .filter((i) => (shinyOnly ? i.shiny : true))}
        availableForms={allPokemon.availableForms}
        mine={mine}
        region={region}
      />
    </div>
  );
}
