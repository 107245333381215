import { Routes, Route } from "react-router-dom";
import AccountsEntry from "./page/accounts/AccountsEntry";
import Costumes from "./page/costumes/Costumes";
import FamilyList from "./page/families/FamilyList";
import HomePage from "./page/HomePage";
import InventoryEntry from "./page/inventory/InventoryEntry";

import "./styles.css";
export default function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="inventory/:inventoryId" element={<InventoryEntry />} />
      <Route path="accounts/:accountName" element={<AccountsEntry />} />
      <Route path="accounts/:accountName/families/:familyId" element={<FamilyList />} />
      <Route path="costumes" element={<Costumes />} />

    </Routes>
  );
}
