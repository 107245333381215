import React from "react";
import { MALE, FEMALE } from "../utils/consts";

/**
 *  ls -l /Users/jbg/Developer/_pogo/_pokeminers/pogo_assets/Images/Pokemon/Addressable\ Assets  |
 *  grep .g2 | tr -s ' ' | cut -d' ' -f9 | cut -d'.' -f1 | cut -c3- | sort -n | uniq
 */
const femaleAsG2Set = [
  3, 12, 19, 20, 25, 26, 41, 42, 44, 45, 64, 65, 84, 85, 97, 111, 112, 118, 119,
  123, 129, 130, 133, 154, 165, 166, 178, 185, 186, 190, 194, 195, 198, 202,
  203, 208, 214, 215, 217, 221, 224, 229, 232, 255, 256, 257, 267, 269, 272,
  274, 275, 307, 308, 315, 316, 317, 322, 323, 332, 350, 396, 397, 398, 399,
  400, 401, 402, 403, 404, 405, 407, 415, 417, 418, 419, 424, 443, 444, 445,
  449, 450, 453, 454, 456, 457, 459, 460, 461, 464, 465, 473, 521,
];

const otherGenderMap = {
  592: {
    // Frillish
    male: "",
    female: "FEMALE",
  },
  593: {
    // Jellicent
    male: "",
    female: "FEMALE",
  },
};

const regionMap = {
  7: "ALOLA",
  8: "GALARIAN",
  9: "HISUIAN",
};

const formMap = {
  351: {
    // castform
    Rainy: 13,
    Sunny: 12,
    Snowy: 14,
  },
};

const prefix_with_species_name = new Set([201, 412]);

const formOverride = {
  150: {
    Armored: "A",
  },
};

const female =
  "https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokedex/female_l.png";
const male =
  "https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokedex/male_l.png";

const getFormOverride = ({ speciesId, form }) => {
  return (
    form &&
    formOverride &&
    formOverride[speciesId] &&
    formOverride[speciesId][form]
  );
};

const calculateFormString = ({
  speciesId,
  speciesName,
  form = "",
  gender,
  region,
}) => {
  // console.log({ speciesId, speciesName, form, gender, region });

  const isRegionalForm = form.toUpperCase() === regionMap[region];

  const isGenderedForm =
    !isRegionalForm && ["male", "female"].includes(form.toLowerCase());

  if (!(form && !isGenderedForm && !isRegionalForm && form !== "Normal")) {
    return "";
  }

  if (prefix_with_species_name.has(speciesId)) {
    return `.f${speciesName.toUpperCase()}_${form
      .toUpperCase()
      .replace(" ", "_")}`;
  }
  return speciesId in formOverride && form in formOverride[speciesId]
    ? `.f${formOverride[speciesId][form]}`
    : `.f${form.toUpperCase().replace(" ", "_")}`;
};

export default function PokemonSprite({
  speciesId,
  speciesName = "",
  isShiny,
  isShadow,
  isPurified,
  original = true,
  isLucky,
  form,
  region,
  costume,
  gender,
}) {
  // console.log({
  //   speciesId,
  //   speciesName,
  //   // isShiny,
  //   // isShadow,
  //   // isPurified,
  //   // original,
  //   form,
  //   region,
  //   costume,
  // });
  if (!speciesId) {
    return <div></div>;
  }
  const genderString =
    gender === MALE ? "male" : gender === FEMALE ? "female" : "";
  const baseStringPokemonAddressable =
    "https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokemon/Addressable%20Assets/";

  const addressable = [
    baseStringPokemonAddressable,

    // By species id.
    `pm${speciesId}`,

    // Regional species.
    original ? `` : `.f${regionMap[region]}`,

    // Special form.
    form
      ? calculateFormString({ form, speciesId, speciesName, gender, region })
      : "",
    costume ? `.${costume}` : "",

    // Gender override base
    (costume || (femaleAsG2Set.includes(speciesId) && original)) && // e.g. Female Alolan forms of Rattata, Raichu do not have alternate forms unlike their Kantonian counterparts.
    genderString === "female"
      ? `.g2`
      : ``,

    // Gender override special
    !femaleAsG2Set.includes(speciesId) &&
    otherGenderMap[speciesId] &&
    otherGenderMap[speciesId][genderString] !== ""
      ? `.f${otherGenderMap[speciesId][genderString]}`
      : "",

    // Form override
    // pm201.fUNOWN_A.s.icon.png

    // Shiny
    isShiny ? ".s" : "",

    // End
    `.icon.png`,
  ];

  // console.log(addressable);

  const luckyString =
    "https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Friends/ui_bg_lucky_pokemon.png";
  const baseStringRocket =
    "https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Rocket/";

  return (
    <>
      {/* // style={{ width: 'inherit', height: 'inherit' }} */}

      {/* {gender && <>
        {gender === '♂' && <img src={male} alt='male' />}
        {gender === '♀' && <img src={female} alt='female' />}
      </>}
      {isPurified && <img src={`${baseStringRocket}ic_purified.png`} />}
      {isShadow && <img src={`${baseStringRocket}ic_shadow.png`} />}
      {isLucky && <img src={luckyString} />}  */}
      <img
        loading="lazy"
        className="sprite-image"
        style={{ maxWidth: "100%", height: "auto" }}
        src={addressable.join("")}
        alt="Sprite"
      />
    </>
  );
}
