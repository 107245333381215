import { useState, useEffect } from "react";
import axios from "axios";
import PokemonSprite from "../../components/PokemonSprite";
import { MALE, FEMALE } from "../../utils/consts";

const NO_GENDERED_SPRITE = [172];

const familyGroups = {
  1: {
    name: "Bulbasaur",
    ids: [1, 2, 3],
  },
  4: {
    name: "Charmander",
    ids: [4, 5, 6],
  },
  7: {
    name: "Squirtle",
    ids: [7, 8, 9],
  },
  12: {
    name: "Butterfree",
    ids: [12],
  },
  20: {
    name: "Raticate",
    ids: [20],
  },
  25: {
    name: "Pikachu",
    ids: [172, 25, 26],
  },
  33: {
    name: "Nidorino",
    ids: [33],
  },
  77: {
    name: "Ponyta",
    ids: [77, 78],
  },
  79: {
    name: "Slowpoke",
    ids: [79, 80, 199],
  },
  94: {
    name: "Gengar",
    ids: [94],
  },
  113: {
    name: "Chansey",
    ids: [440, 113, 242],
  },
  131: { name: "Lapras", ids: [131] },
  133: {
    name: "Eevee",
    ids: [133, 134, 135, 136, 196, 197, 470, 471, 700],
  },
  143: { name: "Snorlax", ids: [143] },
  163: { name: "Hoothoot", ids: [163, 164] },
  175: {
    name: "Togepi",
    ids: [175, 176, 468],
  },
  215: { name: "Sneasel", ids: [215] },
  225: { name: "Delibird", ids: [225] },
  234: { name: "Stantler", ids: [234] },
  238: { name: "Smoochum", ids: [238] },
  265: { name: "Wurmple", ids: [265] },
  280: {
    name: "Ralts",
    ids: [280, 281, 282], // no 280 in this list.
  },
  302: { name: "Sableye", ids: [302] },
  355: {
    name: "Duskull",
    ids: [355, 356, 477],
  },
  453: { name: "Croagunk", ids: [453, 454] },
  522: { name: "Blitzle", ids: [522] },
  613: { name: "Cubchoo", ids: [613, 614] },
  710: { name: "Pumpkaboo", ids: [710, 711] },
};
const familyLookupBySpeciesId = {};
Object.keys(familyGroups).map((key) =>
  familyGroups[key]["ids"].forEach((id) => (familyLookupBySpeciesId[id] = key))
);

export default function Costumes() {
  const [costumes, setCostumes] = useState({});

  async function getCostumes() {
    const response = await axios(`/costumes/`);
    setCostumes(response.data);
  }

  useEffect(() => {
    getCostumes();
  }, []);

  return (
    <>
      <h1>Costumes By Species</h1>
      {costumes &&
        Object.keys(costumes)
          .sort(
            (a, b) =>
              familyLookupBySpeciesId[b] || b - familyLookupBySpeciesId[a] || a
          )
          .map((key) => parseInt(key, 10))
          .map((key) => (
            <div key={key}>
              <h2>{familyGroups[familyLookupBySpeciesId[key]]?.name} Family</h2>
              {costumes[key].map((ccc) => (
                <>
                  <div>
                    <b>{ccc.name}</b>
                    {/* <i>{JSON.stringify(ccc)}</i> */}
                    {(ccc.species.length > 0 ? ccc.species : [key]).map(
                      (speciesId) => (
                        <>
                          {(ccc.forms.length > 0 ? ccc.forms : [""]).map(
                            (formName) => (
                              <>
                                {(!!!ccc.gendered ||
                                  NO_GENDERED_SPRITE.includes(speciesId)) && (
                                  <>
                                    {formName && <span>{formName} Form</span>}
                                    <PokemonSprite
                                      speciesId={speciesId}
                                      region={ccc.regional_form && ccc.region}
                                      original={!!!ccc.regional_form}
                                      form={formName}
                                      costume={ccc.path}
                                    />
                                    {!!!ccc.shiny_blocked && (
                                      <PokemonSprite
                                        speciesId={speciesId}
                                        region={ccc.regional_form && ccc.region}
                                        original={!!!ccc.regional_form}
                                        costume={ccc.path}
                                        form={formName}
                                        isShiny={true}
                                      />
                                    )}
                                  </>
                                )}
                                {ccc.gendered &&
                                  !!!NO_GENDERED_SPRITE.includes(speciesId) && (
                                    <>
                                      &nbsp;
                                      <span className="gender-male">
                                        <span>Male</span>
                                        <PokemonSprite
                                          speciesId={speciesId}
                                          region={
                                            ccc.regional_form && ccc.region
                                          }
                                          original={!!!ccc.regional_form}
                                          costume={ccc.path}
                                          gender={MALE}
                                        />
                                        {!!!ccc.shiny_blocked && (
                                          <PokemonSprite
                                            speciesId={speciesId}
                                            region={
                                              ccc.regional_form && ccc.region
                                            }
                                            original={!!!ccc.regional_form}
                                            costume={ccc.path}
                                            gender={MALE}
                                            isShiny={true}
                                          />
                                        )}
                                      </span>
                                      <span className="gender-female">
                                        <span>Female</span>
                                        <PokemonSprite
                                          speciesId={speciesId}
                                          region={
                                            ccc.regional_form && ccc.region
                                          }
                                          original={!!!ccc.regional_form}
                                          costume={ccc.path}
                                          gender={FEMALE}
                                        />
                                        {!!!ccc.shiny_blocked && (
                                          <PokemonSprite
                                            speciesId={speciesId}
                                            region={
                                              ccc.regional_form && ccc.region
                                            }
                                            original={!!!ccc.regional_form}
                                            costume={ccc.path}
                                            gender={FEMALE}
                                            isShiny={true}
                                          />
                                        )}
                                      </span>
                                    </>
                                  )}
                              </>
                            )
                          )}
                        </>
                      )
                    )}
                  </div>
                </>
              ))}
            </div>
          ))}
    </>
  );
}
