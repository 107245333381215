export const PokemonIVsDisplay = ({ atk = 0, def = 0, sta = 0 }) => {
  return atk === 15 && def === 15 && sta === 15 ? (
    <div style={{ maxWidth: "100%", height: "auto" }}>💯</div>
  ) : (
    <>
      <div style={{ maxWidth: "100%", height: "auto" }}>
        <div>
          <span style={{ minWidth: "8px" }}>{atk}</span>
          <progress
            aria-label="Attack"
            id={atk}
            title={`Attack: ${atk}`}
            max={"15"}
            value={atk}
            style={{ maxWidth: "80%", color: atk === 15 ? "red" : "orange" }}
          ></progress>
        </div>
        <div>
          <span style={{ minWidth: "8px" }}>{def}</span>
          <progress
            aria-label="Defense"
            id={def}
            title={`Defense: ${def}`}
            max={"15"}
            value={def}
            style={{ maxWidth: "80%", color: def === 15 ? "red" : "orange" }}
          ></progress>
        </div>
        <div>
          <span style={{ minWidth: "8px" }}>{sta}</span>
          <progress
            aria-label="HP"
            id={sta}
            title={`HP: ${sta}`}
            max={"15"}
            value={sta}
            style={{ maxWidth: "80%", color: sta === 15 ? "red" : "orange" }}
          ></progress>
        </div>
      </div>
    </>
  );
};
