import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import PokemonSprite from "../../components/PokemonSprite";
import { Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, Box, TextField, Link } from "@mui/material";
import { PokemonIVsDisplay } from "../../components/PokemonIVsDisplay";
const forms = {
  201: {
    A: `pm201.fUNOWN_A.s.icon.png`,
  },
};
export default function InventoryList({
  inventory,
  availableForms = [],
  mine = false,
}) {
  const [balls, setBalls] = useState([]);
  useEffect(() => {
    async function getBalls() {
      const response = await axios("/balls/");
      setBalls(response.data);
    }
    getBalls();
  }, []);
  const luckyString =
    "https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Friends/ui_bg_lucky_pokemon.png";
  const baseStringRocket =
    "https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Rocket/";

  if (!inventory) {
    return <></>;
  }

  const deleteFromInventory = async (id) => {
    await axios.delete(`/inventory/${id}`);
  };

  const setShiny = async (id, isShiny) => {
    await axios.put(`/inventory/${id}/shiny`, { newState: isShiny });
  };

  const setTraded = async (id, isTraded) => {
    await axios.put(`/inventory/${id}/traded`, { newState: isTraded });
  };

  const setBall = async (id, ball) => {
    await axios.put(`/inventory/${id}/ball`, { newState: ball });
  };

  const setForm = async (id, formId) => {
    await axios.put(`/inventory/${id}/form`, { newState: formId });
  };

  const setTradeable = async (id, isTradeable) => {
    await axios.put(`/inventory/${id}/tradeable`, { newState: isTradeable });
  };

  const DATE_WIDTH = 150;
  const IV_WIDTH = 25;
  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 60,
      renderCell: (params) => (
        <div className={"pkmn-sprite-in-table"}>
          <PokemonSprite
            speciesName={params.row.name}
            speciesId={params.row.dex_id}
            original={params.row.regionalSpecies.original}
            isShiny={params.row.shiny}
            purified={params.row.purified}
            region={params.row.regionalSpecies.region}
            form={params.row.form?.name}
            isLucky={params.row.lucky}
            isShadow={params.row.shadow}
            gender={params.row.gender}
          />
        </div>
      ),
      valueGetter: (params) => params.row.dex_id,
    },
    {
      field: "name",
      headerName: "Name",
      width: 100,
      renderCell: (params) => (
        <>
          <div style={{ textAlign: "center" }}>
            <Link target={`_blank`} href={`/inventory/${params.row.id}`}>
              {params.row.name}
            </Link>
            {params.row?.form?.name && params.row.form.name !== "Normal" && (
              <div>
                <span>{params.row.form.displayName}</span>
              </div>
            )}
          </div>
        </>
      ),
      valueGetter: (params) => params.row.dex_id,
    },
    {
      field: "form",
      headerName: "Form",
      width: 150,
      valueGetter: (params) => params.form_id,
      renderCell: (params) => (
        <Autocomplete
          id="pkmn-form-select"
          // disabled={!!!mine}
          disabled={
            !!params.row.form_id ||
            availableForms.filter(
              (af) => af.regional_species === params.row.regional_species_id
            ).length === 0
          }
          sx={{ width: 125 }}
          autoHighlight
          options={availableForms.filter(
            (af) => af.regional_species === params.row.regional_species_id
          )}
          onChange={(_, b) => {
            params.row.form_id = b.id;
            setForm(params.row.id, b.id);
          }}
          getOptionLabel={(option) => option.displayName}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <div style={{ maxWidth: "60px" }}>
                <PokemonSprite
                  speciesId={params.row.dex_id}
                  speciesName={params.row.name}
                  isShiny={params.row.shiny}
                  form={option.name}
                  region={params.row.regionalSpecies.region}
                  gender={params.row.gender}
                />
              </div>
              &nbsp;{option.displayName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={mine ? "Form" : "Unknown"}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      ),
    },
    { field: "gender", headerName: "Gender", width: 80 },
    {
      field: "rocket",
      headerName: "Rocket",
      width: 100,
      renderCell: (params) =>
        params.row.shadow ? (
          <img
            style={{ maxWidth: "100%", height: "auto" }}
            src={`${baseStringRocket}ic_shadow.png`}
          />
        ) : params.row.purified ? (
          <img
            style={{ maxWidth: "100%", height: "auto" }}
            src={`${baseStringRocket}ic_purified.png`}
          />
        ) : (
          <></>
        ),
      valueGetter: (params) =>
        params.row.shadow ? "shadow" : params.row.purified ? "purified" : null,
    },
    {
      field: "traded",
      headerName: "Traded",
      width: 100,
      renderCell: (params) =>
        params.row.lucky ? (
          <img style={{ maxWidth: "100%", height: "auto" }} src={luckyString} />
        ) : (
          <Switch
            disabled={!!!params?.row?.owner?.mine || params.row.shadow}
            checked={params.row.traded}
            onChange={() => {
              params.row.traded = !!!params.row.traded;
              setTraded(params.row.id, params.row.traded);
            }}
            color="default"
          />
        ),
      valueGetter: (params) => (params.row.lucky ? "lucky" : null),
    },
    {
      field: "lastScanned",
      headerName: "Last Scanned",
      width: DATE_WIDTH,
      renderCell: (params) => (
        <>{moment(params.row.lastScanned).format("ll")}</>
      ),
      valueGetter: (params) => moment(params.row.lastScanned).toISOString(),
    },
    { field: "level", headerName: "Level", width: IV_WIDTH },
    { field: "stat_cp", headerName: "CP", width: IV_WIDTH * 4 },
    {
      field: "ivs",
      headerName: "IVs",
      width: 120,
      renderCell: (params) => (
        <PokemonIVsDisplay
          atk={params.row.iv_atk}
          def={params.row.iv_def}
          sta={params.row.iv_sta}
        />
      ),
      valueGetter: (params) =>
        params?.iv_atk ?? 0 + params?.iv_def ?? 0 + params?.iv_sta ?? 0,
    },
    { field: "iv_atk", headerName: "ATK", width: IV_WIDTH },
    { field: "iv_def", headerName: "DEF", width: IV_WIDTH },
    { field: "iv_sta", headerName: "HP", width: IV_WIDTH },
    {
      field: "ball",
      headerName: "Ball",
      width: mine ? 200 : 50,
      renderCell: (params) =>
        mine ? (
          <Autocomplete
            id="ball-select"
            // disabled={!!!mine}
            sx={{ width: mine ? 200 : 50 }}
            autoHighlight
            options={balls}
            value={balls.find((b) => b.id === params.row.ball) || null}
            onChange={(_, b) => {
              params.row.ball = b.id;
              setBall(params.row.id, b.id);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Items/${option.sprite}.png`}
                  alt={`${option.name}`}
                />
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={mine ? "Ball Type" : "Unknown"}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        ) : (
          <>
            {balls.find((b) => b.id === params.row.ball) && (
              <img
                style={{ maxHeight: "auto", maxWidth: "100%" }}
                src={`https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Items/${
                  balls.find((b) => b.id === params.row.ball).sprite
                }.png`}
              />
            )}
          </>
        ),
    },
    {
      field: "shiny",
      headerName: "Shiny",
      width: 75,
      renderCell: (params) =>
        params?.row?.owner?.mine ? (
          <Switch
            checked={params.row.shiny}
            onChange={() => {
              params.row.shiny = !!!params.row.shiny;
              setShiny(params.row.id, params.row.shiny);
            }}
            color="default"
          />
        ) : (
          <span>{params.row.shiny ? "Shiny" : "Not Shiny"}</span>
        ),
    },
    {
      field: "caught",
      headerName: "Caught",
      width: DATE_WIDTH,
      renderCell: (params) =>
        params && params.row.catchDate
          ? moment(params.row.catchDate).format("LL")
          : "Unknown",
    },
    {
      field: "availableForTrade",
      headerName: "For Trade",
      width: 75,
      renderCell: (params) => (
        <Switch
          disabled={
            !!!mine || // can only trade something i own
            params.row.lucky || // can't be lucky
            params.row.shadow || // or shadow
            params.row.traded // or already traded
          }
          checked={params.row.availableForTrade}
          onChange={() => {
            params.row.availableForTrade = !!!params.row.availableForTrade;
            setTradeable(params.row.id, params.row.availableForTrade);
          }}
          color="default"
        />
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          disabled={!!!mine}
          startIcon={<DeleteIcon />}
          onClick={() => deleteFromInventory(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];
  return (
    <div>
      <div style={{ display: "flex", height: "24px", width: "100%" }}>
        <div style={{ flexGrow: 1, height: 600, width: "100%" }}>
          <DataGrid
            initialState={{
              sorting: {
                sortModel: mine
                  ? [{ field: "stat_cp", sort: "desc" }]
                  : [{ field: "name", sort: "asc" }],
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  form: mine,
                  delete: mine,
                  availableForTrade: mine,
                  stat_cp: mine,
                  caught: mine,
                  ivs: mine,
                  lastScanned: mine,
                  traded: mine,
                  iv_atk: false,
                  iv_def: false,
                  iv_sta: false,
                },
              },
            }}
            rows={inventory}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );
}
