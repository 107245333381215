import Button from "@mui/material/Button";
import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PokemonSprite from "../../components/PokemonSprite";
import moment from "moment";

export default function InventoryEntry() {
  const { inventoryId } = useParams();

  const [critter, setCritter] = useState([]);
  const [region, setRegion] = useState({});
  const [regionalSpecies, setRegionalSpecies] = useState({});
  const [species, setSpecies] = useState({});
  const [form, setForm] = useState({});
  const [owner, setOwner] = useState({});

  async function getCritter(inventoryId) {
    const response = await axios(`/inventory/${inventoryId}/`);
    setCritter(response.data.inventory);
    setRegion(response.data.region);
    setRegionalSpecies(response.data.regionalSpecies);
    setSpecies(response.data.species);
    setForm(response.data.form);
    setOwner(response.data.owner);
  }

  const deleteFromInventory = async (id) => {
    await axios.delete(`/inventory/${id}`);
    getCritter(inventoryId);
  };

  const setShiny = async (id, isShiny) => {
    await axios.put(`/inventory/${id}/shiny`, { newState: isShiny });
    getCritter(inventoryId);
  };

  const setTradeable = async (id, isTradeable) => {
    await axios.put(`/inventory/${id}/tradeable`, { newState: isTradeable });
    getCritter(inventoryId);
  };

  useEffect(() => {
    getCritter(inventoryId);
  }, [inventoryId]);

  return (
    <div>
      <h1>
        {regionalSpecies && !!!regionalSpecies.original && region.prefix}{" "}
        {regionalSpecies && species.name} #{inventoryId}
      </h1>
      <h2>
        Original Trainer:{" "}
        <a href={`/accounts/${owner?.account}`}>{owner?.account}</a>
      </h2>

      <PokemonSprite
        speciesId={critter.dex_id}
        original={regionalSpecies.original}
        isShiny={critter.shiny}
        purified={critter.purified}
        region={regionalSpecies.region}
        form={form?.name}
        isLucky={critter.lucky}
        isShadow={critter.shadow}
        gender={critter.gender}
      />
      <div>
        {critter.shadow && <span>Shadow</span>}
        {critter.purified && <span>Purified</span>} {species.name}(
        {critter.dex_id})&nbsp;
        {regionalSpecies.original || <span>— {`${region.prefix} Form`}</span>}
        {critter.lucky && <span>&nbsp;(Lucky!)</span>}
      </div>
      <div className="stats">
        <h4>Stats</h4>
        <div>
          {form && !!!form.is_default && <span> | {region.prefix} Form</span>}
        </div>
        {
          critter.shiny ? (
            <div>
              Shiny{" "}
              {owner.mine && (
                <Button
                  variant="contained"
                  onClick={() => setShiny(critter.id, false)}
                >
                  Not shiny? :(
                </Button>
              )}
            </div>
          ) : (
            <div>
              Not shiny.{" "}
              {owner.mine && (
                <Button
                  variant="contained"
                  onClick={() => setShiny(critter.id, true)}
                >
                  Set Shiny
                </Button>
              )}
            </div>
          ) // todo: mark as shiny button
        }
        {critter.availableForTrade ? (
          <div>
            For Trade!
            {owner.mine && (
              <Button
                variant="contained"
                onClick={() => setTradeable(critter.id, false)}
              >
                Keep
              </Button>
            )}
          </div>
        ) : (
          <div>
            Not for trade.
            {owner.mine && (
              <Button
                variant="contained"
                onClick={() => setTradeable(critter.id, true)}
              >
                Offer
              </Button>
            )}
          </div>
        )}
        {critter.deleted ? (
          <div>Deleted!</div>
        ) : (
          <div>
            {owner.mine && (
              <Button
                variant="contained"
                onClick={() => deleteFromInventory(critter.id)}
              >
                Delete
              </Button>
            )}
          </div>
        )}
        {owner.mine &&
          (critter.lucky ? (
            <div>Lucky!</div>
          ) : (
            <div>Not lucky, maybe traded</div>
          ))}
        {owner.mine && (
          <div>
            CP {critter.stat_cp} | {critter.iv_atk}/{critter.iv_def}/
            {critter.iv_sta}
          </div>
        )}
        {critter.location && critter.location > -1 && (
          <div className="location">Location: {critter.location}</div>
        )}
        {critter.buddy_level > -1 && (
          <div className="buddy">Buddy Level: {critter.buddy_level}</div>
        )}
        {critter.mega_level > -1 && (
          <div className="mega">Mega Level: {critter.mega_level}</div>
        )}
        {critter.costume_id && critter.costume_id > -1 && (
          <div className="costume">Cotume: {critter.costume_id}</div>
        )}
      </div>
      <div className="caught">
        <h4>
          Caught {moment(moment(critter.catchDate).toDate()).format("LL")}
        </h4>
      </div>
    </div>
  );
}
