import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InventoryList from "../inventory/InventoryList";
import axios from "axios";

import { Autocomplete, FormGroup, TextField } from "@mui/material";
import _ from "lodash";
export default function AccountsEntry() {
  const { accountName } = useParams();
  const [allSpecies, setAllSpecies] = useState([]);
  const [allRegions, setAllRegions] = useState([]);

  const [account, setAccount] = useState({});
  const [forTrade, setForTrade] = useState([]);
  const [region, setRegion] = useState();
  const [familyId, setFamilyId] = useState();
  const [shinyOnly, setShinyOnly] = useState();
  const [legendaryOnly, setLegendaryOnly] = useState();
  const [rocketOptions, setRocketOptions] = useState([]);

  async function getAccount(accountName) {
    const response = await axios(`/accounts/${accountName}`);
    setAccount(response.data);
  }

  async function getForTrade(accountName) {
    const response = await axios(`/accounts/${accountName}/for-trade`);
    setForTrade(response.data);
  }

  useEffect(() => {
    getAccount(accountName);
  }, [accountName]);

  useEffect(() => {
    getForTrade(accountName);
  }, [account]);

  useEffect(() => {
    async function getAllSpecies() {
      const response = await axios("/species/");
      setAllSpecies(response.data);
    }
    getAllSpecies();
  }, []);

  useEffect(() => {
    async function getAllRegions() {
      const response = await axios("/regions/");
      setAllRegions(response.data);
    }
    getAllRegions();
  }, []);

  return (
    <>
      <h1>{account.name} &mdash; Available for Trade</h1>
      <div>{account.team && <span>Team {account.team}</span>}</div>

      <div className="family-list-controls">
        <FormGroup style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          {/* <div className="autocomplete">
            <Autocomplete
              disabled={!allSpecies}
              disablePortal
              id="species-name"
              getOptionLabel={(option) => option.name}
              onChange={(_, b) => b && setFamilyId(b.family_id)}
              //   getOptionSelected={(option, value) => option === value}
              options={allSpecies}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Family" />}
            />
          </div> */}
          {/* <FormControlLabel
            control={
              <Switch
                checked={shinyOnly}
                onChange={(e) => setShinyOnly(e.target.checked)}
              />
            }
            label="Shiny"
          />
          <FormControlLabel
            control={
              <Switch
                checked={legendaryOnly}
                onChange={(e) => setLegendaryOnly(e.target.checked)}
              />
            }
            label="Legendary"
          /> */}
        </FormGroup>
      </div>
      <InventoryList
        inventory={
          forTrade &&
          forTrade.inventory &&
          forTrade.inventory
            .filter((i) =>
              region ? i.regionalSpecies.region === region : true
            )
            .filter((i) => (rocketOptions.includes("Shadow") ? i.shadow : true))
            .filter((i) => (shinyOnly ? i.shiny : true))
            .filter((i) => (familyId ? i.family.id === familyId : true))
        }
        mine={false}
      />
    </>
  );
}
